import { ref } from '@vue/composition-api';
import store from '@/store';

import toast from '@/utils/toast';
import { useRouter } from '@/@core/utils/utils';
import invoiceStoreModule from '../finance/invoice/invoiceStoreModule';

export default function usePreviewInvoice() {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }

  // Use toast
  const toastification = toast();

  const { route } = useRouter();
  const invoiceId = ref(route.value.params.id);
  const invoiceUUID = ref(route.value.params.uuid);

  const isLoading = ref(false);
  const invoicePreviewUrl = ref(null);
  const loadImage = ref(false);

  //   API Call
  const fetchPreviewUrl = loadImageValue => {
    loadImage.value = !!(loadImageValue && loadImageValue === true);
    isLoading.value = true;
    store
      .dispatch('invoice/previewInvoiceWithUUID', { id: invoiceId.value, uuid: invoiceUUID.value, loadImage: loadImageValue && loadImageValue === true ? 1 : 0 })
      .then(response => {
        invoicePreviewUrl.value = response.data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const downloadPdf = () => {
    window.location.href = invoicePreviewUrl.value;
  };

  return {
    loadImage,
    isLoading,
    fetchPreviewUrl,
    invoicePreviewUrl,
    downloadPdf,
  };
}
