<template>
  <!-- Under maintenance-->
  <div>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1"
    >

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            {{ t('Bạn ơi chờ chút nhé!') }}
          </h2>
          <p class="mb-2">
            {{ t('Chúng tôi đang tìm kiếm hóa đơn cho bạn') }} <b-spinner

              class="text-center"
              variant="secondary"
            />
          </p>

          <!-- image -->
          <b-img
            fluid
            src="@/assets//images/banner/img_load_invoice.svg"
            alt="Error page"
          />
        </div>

      </div>

    </div>
    <div
      v-if="invoicePreviewUrl && !isLoading"
      class="invoice-container"
    >
      <iframe
        v-if="!loadImage"
        :src="invoicePreviewUrl"
        style="width: 100vw; height: 100vh; border: none"
      />
      <b-img
        v-else
        :src="invoicePreviewUrl"
        style="width: 100vw;"
      />
      <div
        v-if="!loadImage"
        class="action-container bg-primary text-center"
      >
        <small class="text-center text-white pt-1">{{ t('Một số thiết bị không hỗ trợ hiển thị trực tiếp bản PDF. Nếu không xem được vui lòng tải xuống hoặc xem dạng hình ảnh') }}</small>
        <div
          class="my-1 text-center"
        >
          <b-button
            class="ml-2"
            variant="warning"
            @click="downloadPdf()"
          >
            {{ t('Tải bản PDF') }}
          </b-button>
          <b-button
            class="ml-2"
            variant="danger"
            @click="fetchPreviewUrl(true)"
          >
            {{ t('Xem dạng ảnh') }}
          </b-button>
        </div>
      </div>
    <!-- <b-img
      v-if="invoicePreviewUrl && !isLoading"
      :src="invoicePreviewUrl"
    /> -->
    </div>

    <div
      v-if="!isLoading && !invoicePreviewUrl"
    >

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            {{ t('Không tìm thấy hóa đơn') }} 🕵🏻‍♀️
          </h2>
          <p class="mb-2">
            {{ t('Lỗi! 😖 Hóa đơn bạn tìm kiếm không tồn tại.') }}
          </p>

          <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{ path: '/' }"
          >
            {{ t('Quay về trang chủ') }}
          </b-button>

          <!-- image -->
          <b-img
            fluid
            src="@/assets/images/pages/error.svg"
            alt="Error page"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */
import {
  BButton, BImg, BSpinner,
} from 'bootstrap-vue';

import store from '@/store/index';
// eslint-disable-next-line import/no-cycle
import { useUtils as useI18nUtils } from '@core/libs/i18n';
// eslint-disable-next-line import/no-cycle
import usePreviewInvoice from './usePreviewInvoice';

export default {
  components: {
    BButton, BImg, BSpinner,
  },
  data() {
    return {
      invoiceId: null,
    };
  },
  setup() {
    const { t } = useI18nUtils();
    const {
      isLoading, fetchPreviewUrl, invoicePreviewUrl, loadImage, downloadPdf,
    } = usePreviewInvoice();
    return {
      isLoading, fetchPreviewUrl, invoicePreviewUrl, loadImage, downloadPdf, t,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
  created() {
    this.fetchPreviewUrl();
  },

};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.action-container {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 100px;
}
.invoice-container {
  width: 100vw;
  height: 100px;
}
</style>
